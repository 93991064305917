<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="5">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="fa-barcode"/>  New Category
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            </CCardHeader>
                            <CCardBody>
                                <CForm @submit.prevent="createCategory">
                            <CRow>
                                <CCol lg="12"> 
                                    <CInput 
                                        :value.sync="$v.category.color.$model"
                                        :lazy="false"
                                        :isValid="checkIfValid('color')"
                                        label="Color"
                                        type="color" 
                                        v-model="category.color"
                                    />
                                </CCol>
                            </CRow> 
                                    <CRow>
                                        <CCol lg="12">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.category.category.$model"
                                                :isValid="checkIfValid('category')"
                                                label="Name" 
                                                type="text" 
                                                autocomplete="off" 
                                                v-nospecialcharacter
                                                invalidFeedback="Must be three(3) characters."
                                                v-tocapitalize
                                                v-model="category.category"
                                           >
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol col="12" class="text-right">
                                            <CButton 
                                                :disabled="!isValid"
                                                title="There is required field that is not filled out or the Attachment Checklist is set to true but there is no entry made."
                                                type="submit" 
                                                shape="pill" 
                                                color="primary"
                                            >
                                                <font-awesome-icon icon="save"/> Save
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol lg="7">
                        <template v-if="$store.getters['can']('document-template-category-add-attachment-checklist')">
                            <CCard>
                                <CCardHeader>
                                    <font-awesome-icon icon="link"/>
                                    Category Attachment Checklist
                                    <CButton 
                                        size="sm"
                                        :disabled="category_set_list.length > 0"
                                        :color="collapse_attachment ? 'success' : 'danger'" 
                                        @click="collapse_attachment = !collapse_attachment"
                                        class="float-right" 
                                    >
                                        <font-awesome-icon 
                                            :icon="collapse_attachment ? 'chevron-circle-down': 'chevron-circle-left'"
                                        /> {{ collapse_attachment ? 'With Attachment Checklist' : 'Without Attachment Checklist' }}
                                    </CButton>
    
    
                                    <!-- <p-check 
                                        class="float-right p-icon p-bigger p-round" 
                                        color="success" off-color="danger" toggle
                                        v-model="collapse_attachment"
                                    >
                                        <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                        With Checklist
                                        <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                        <label slot="off-label">Without Checklist</label>
                                    </p-check>   -->
    
                                </CCardHeader>
                                <CCollapse :show="collapse_attachment">
                                    <CCardBody>
                                        <CRow>
                                            <CCol lg="12">
                                                <!-- <CAlert color="info">
                                                    <p-radio 
                                                        value="default"
                                                        class="p-default p-curve" 
                                                        name="color" 
                                                        color="danger-o"
                                                        @change="selectionChange"
                                                        v-model="selected_attachment_type">Use Default in Checklist</p-radio>

                                                    <p-radio 
                                                        value="set"
                                                        class="p-default p-curve" 
                                                        name="color" 
                                                        color="danger-o"
                                                        @change="selectionChange"
                                                        v-model="selected_attachment_type">Use Set in Checklist</p-radio>
                                                </CAlert> -->
                                            </CCol>
                                        </CRow>
                                        <template v-if="selected_attachment_type == 'default'">
                                            <CRow>
                                                <CCol lg="4">
                                                    Name
                                                </CCol>
                                                <CCol lg="4">
                                                    Description
                                                </CCol>
                                                <CCol lg="4">
                                                    Required <a-popover title="NOTE:" placement="topRight">
                                                        <template slot="content" >
                                                        <p>When the attachment checklist is required, saving or starting <br>document routing is not possible without it.</p>
                                                        </template>
                                                            <font-awesome-icon style="color: #4caf50; " icon="info-circle"/>
                                                    </a-popover>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="4">
                                                    <CInput 
                                                        :class="!attachment.name ? 'has-inp-error' : 'has-inp-success'"
                                                        placeholder="PO Document"
                                                        type="text" 
                                                        autocomplete="off" 
                                                        v-model="attachment.name"
                                                    >
                                                    </CInput>
                                                </CCol>
                                                <CCol lg="4">
                                                    <CTextarea
                                                        type="text" 
                                                        placeholder="Enter brief description..." 
                                                        autocomplete="off"  
                                                        class="grp-text-area"
                                                        
                                                        v-model="attachment.description"
                                                    />
                                                </CCol>
                                                <CCol lg="2">
                                                    <CSwitch 
                                                        color="success" 
                                                        variant="opposite" 
                                                        :checked.sync="attachment.required"
                                                        v-bind="labelTxt"  
                                                    />
    
    
                                                    <!-- <p-check 
                                                        class="p-icon p-bigger p-round" 
                                                        color="success" off-color="warning" toggle
                                                        v-model="attachment.required"
                                                    >
                                                        <font-awesome-icon class="icon" icon="check" slot="extra"/>
                                                        Yes
                                                        <font-awesome-icon class="icon" icon="times" slot="off-extra"/>
                                                        <label slot="off-label">No</label>
                                                    </p-check>   -->
                                                </CCol>
                                                <CCol lg="2">
                                                    <CButton
                                                        :disabled="!attachment.name"
                                                        color="success"
                                                        @click="attachment.name ? addAttachmentChecklist() : ''"
                                                        :title="attachment.name ? 'Add Attachment Checklist.' : 'Attachment cannot be empty.'"
                                                    >
                                                    <!-- <font-awesome-icon icon="plus"/> -->
                                                    
                                                    <font-awesome-icon icon='plus'/>
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="12">
                                                    <CDataTable
                                                            :key="attachment_key"
                                                            :items="attachment_list"
                                                            :fields="attachment_field_list"
                                                            :items-per-page="10"
                                                            size="sm"
                                                            border
                                                            sorter
                                                        >
                                                        <template #required={item}>
                                                            <td>
                                                                <CSwitch 
                                                                    color="success" 
                                                                    variant="opposite" 
                                                                    :checked.sync="item.required"
                                                                    v-bind="labelTxt"  
                                                                />
        
                                                            </td>
                                                        </template>
                                                        <template #description={item}>
                                                            <td>
                                                                <CTextarea
                                                                    type="text" 
                                                                    placeholder="Enter brief description..." 
                                                                    autocomplete="off"  
                                                                    class="grp-text-area"
                                                                    
                                                                    v-model="item.description"
                                                                />
                                                            </td>
                                                        </template>
                                                        <template #name={item}>
                                                            <td>
                                                                <CInput 
                                                                    :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                                    placeholder="PO Document"
                                                                    type="text" 
                                                                    autocomplete="off" 
                                                                    v-model="item.name"
                                                                >
                                                                </CInput>
                                                            </td>
                                                        </template>
                                                        <template #action="{item, index}">
                                                            <td>
                                                                <center>
                                                                    <CButton 
                                                                        color="danger" 
                                                                        shape="pill" 
                                                                        size="sm"
                                                                        @click="removeAttachmentChecklist(attachment_list, item, index)"
                                                                    >
                                                                        <font-awesome-icon icon="times"/>
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                    </CDataTable>
                                                </CCol>
                                            </CRow> 
                                        </template>
                                        <template v-else>
                                            <CRow>
                                                <CCol lg="4">
                                                    Set Name
                                                </CCol>
                                                <CCol lg="4">
                                                    Description
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="4">
                                                    <CInput 
                                                        placeholder="Re Invoice Set 1"
                                                        type="text" 
                                                        autocomplete="off" 
                                                        v-model="new_set.name"
                                                    >
                                                    </CInput>
                                                </CCol>
                                                <CCol lg="4">
                                                    <CTextarea
                                                        type="text" 
                                                        placeholder="Enter brief description..." 
                                                        autocomplete="off"  
                                                        class="grp-text-area"
                                                        
                                                        v-model="new_set.description"
                                                    />
                                                </CCol>
                                                <CCol lg="3">
                                                    <CButton
                                                        :disabled="!new_set.name"
                                                        color="warning"
                                                        @click="showAttachmentChecklistModal(new_set, true)"
                                                    >
                                                    Attachment Checklists ( {{ new_set.attachment_checklist.length }} )
                                                    </CButton>
                                                </CCol>
                                                <CCol lg="1">
                                                    <CButton
                                                        :disabled="!new_set.name || new_set.attachment_checklist.length == 0"
                                                        color="success"
                                                        @click="addNewSet()"
                                                    >
                                                        <font-awesome-icon icon='plus'/>
                                                    </CButton>
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol lg="12">
                                                    <CDataTable
                                                            :key="category_set_key"
                                                            :items="category_set_list"
                                                            :fields="category_set_field_list"
                                                            :items-per-page="10"
                                                            size="sm"
                                                            border
                                                            sorter
                                                        >
                                                        <template #attachment_checklist={item}>
                                                            <td>
                                                                <center>
                                                                    <CButton
                                                                        color="warning"
                                                                        @click="showAttachmentChecklistModal(item, false)"
                                                                    >
                                                                        Attachments Checklist ( {{ item.attachment_checklist.length }} )
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                        <template #description={item}>
                                                            <td>
                                                                <CTextarea
                                                                    type="text" 
                                                                    placeholder="Enter brief description..." 
                                                                    autocomplete="off"  
                                                                    class="grp-text-area"
                                                                    
                                                                    v-model="item.description"
                                                                />
                                                            </td>
                                                        </template>
                                                        <template #name={item}>
                                                            <td>
                                                                <CInput 
                                                                    :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                                    placeholder="PO Document"
                                                                    type="text" 
                                                                    autocomplete="off" 
                                                                    v-model="item.name"
                                                                >
                                                                </CInput>
                                                            </td>
                                                        </template>
                                                        <template #action="{item, index}">
                                                            <td>
                                                                <center>
                                                                    <CButton 
                                                                        color="danger" 
                                                                        shape="pill" 
                                                                        size="sm"
                                                                        @click="removeAttachmentChecklist(category_set_list, item, index)"
                                                                    >
                                                                        <font-awesome-icon icon="times"/>
                                                                    </CButton>
                                                                </center>
                                                            </td>
                                                        </template>
                                                    </CDataTable>
                                                </CCol>
                                            </CRow> 
                                        </template>
                                    </CCardBody>
                                </CCollapse>
                            </CCard>
                        </template>
                    </CCol>
                </CRow>
                <CModal
                    :show.sync="attachment_checklists_modal"
                    color="primary"
                    :closeOnBackdrop="false"
                    size="lg"
                    >
                    <template #header>
                        <h6> <font-awesome-icon icon="comment-dots"/> Attachment Checklists for {{selected_set?.name}} </h6>
                        <CButton 
                            class="pull-right" 
                            color="light" 
                            shape="pill" 
                            size="sm"
                            @click="attachment_checklists_modal = false"
                        >
                            <font-awesome-icon  size="sm" icon="times"/>
                        </CButton>
                    </template>
                    <template #footer>
                        <CRow lg="12">
                            <CButton 
                                class="pull-right" 
                                color="primary" 
                                @click="attachment_checklists_modal = false"
                            >
                                Close
                            </CButton>
                        </CRow>
                    </template>
                    <template #body-wrapper>
                        <CCardBody>
                            <CRow>
                                <CCol lg="4">
                                    Name
                                </CCol>
                                <CCol lg="4">
                                    Description
                                </CCol>
                                <CCol lg="4">
                                    Required <a-popover title="NOTE:" placement="topRight">
                                        <template slot="content" >
                                        <p>When the attachment checklist is required, saving or starting <br>document routing is not possible without it.</p>
                                        </template>
                                            <font-awesome-icon style="color: #4caf50; " icon="info-circle"/>
                                    </a-popover>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="4">
                                    <CInput 
                                        placeholder="PO Document"
                                        type="text" 
                                        autocomplete="off" 
                                        v-model="new_checklist.name"
                                    >
                                    </CInput>
                                </CCol>
                                <CCol lg="4">
                                    <CTextarea
                                        type="text" 
                                        placeholder="Enter brief description..." 
                                        autocomplete="off"  
                                        class="grp-text-area"
                                        
                                        v-model="new_checklist.description"
                                    />
                                </CCol>
                                <CCol lg="2">
                                    <CSwitch 
                                        color="success" 
                                        variant="opposite" 
                                        v-bind="labelTxt"  
                                        :checked.sync="new_checklist.required"
                                    />
                                </CCol>
                                <CCol lg="2">
                                    <CButton
                                        :disabled="!new_checklist.name"
                                        color="success"
                                        @click="addNewChecklist()"
                                    >
                                        <font-awesome-icon icon='plus'/>
                                    </CButton>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                            :key="attachment_checklist_key"
                                            :items="attachment_checklist_list"
                                            :fields="attachment_checklist_field_list"
                                            :items-per-page="10"
                                            size="sm"
                                            border
                                            sorter
                                        >
                                        <template #required={item}>
                                            <td>
                                                <CSwitch 
                                                    color="success" 
                                                    variant="opposite" 
                                                    :checked.sync="item.required"
                                                    v-bind="labelTxt"  
                                                />

                                            </td>
                                        </template>
                                        <template #description={item}>
                                            <td>
                                                <CTextarea
                                                    type="text" 
                                                    placeholder="Enter brief description..." 
                                                    autocomplete="off"  
                                                    class="grp-text-area"
                                                    
                                                    v-model="item.description"
                                                />
                                            </td>
                                        </template>
                                        <template #name={item}>
                                            <td>
                                                <CInput 
                                                    :class="!item.name ? 'has-inp-error' : 'has-inp-success'"
                                                    placeholder="PO Document"
                                                    type="text" 
                                                    autocomplete="off" 
                                                    v-model="item.name"
                                                >
                                                </CInput>
                                            </td>
                                        </template>
                                        <template #action="{item, index}">
                                            <td>
                                                <center>
                                                    <CButton 
                                                        :disabled="!adding_new_set && attachment_checklist_list.length == 1"
                                                        color="danger" 
                                                        shape="pill" 
                                                        size="sm"
                                                        @click="removeAttachmentChecklist(attachment_checklist_list, item, index)"
                                                    >
                                                        <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </center>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow> 
                        </CCardBody>
                    </template>
                </CModal>
            </div>
        </div>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateCategoryCreate',
    data () {
        return {
            labelTxt: {
                labelOn: 'Yes',
                labelOff: 'No'
            },
            checklist: true,
            attachment_list: [],
            attachment_field_list:[
                { key: 'name',},
                { key: 'description',},
                { key: 'required' },
                { key: 'action', sorter: false}
            ],

            selected_attachment_type: 'set',

            attachment: {
                name: null,
                description: null,
                required: true,
            },
            
            attachment_key: 0,
            collapse_attachment: true, 

            category: {
                category: null,
                color: null,       
            },

            new_set: {
                name: null,
                description: null,
                attachment_checklist: []
            },

            adding_new_set: false,

            new_checklist: {
                name: null,
                description: null,
                required: false,
            },

            selected_set: null,

            category_set_key: 0,
            category_set_list: [
                // { name: 'Set1', description: 'Ikaw matalino', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set2', description: 'Ikaw bobo', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: 'description1', required: true},
                // ] },
                // { name: 'Set3', description: 'Ikaw utak talaba', attachment_checklist:[
                //     {name: 'PO routing', description: null, required: false},
                //     {name: 'PI routing', description: null, required: false},
                // ] },
                // { name: 'Set4', description: 'Imong mama', attachment_checklist:[
                //     {name: 'PO routing', description: 'sample', required: true},
                //     {name: 'JO routing', description: 'description', required: true},
                //     {name: 'PI routing', description: null, required: true},
                // ] },
            ],
            attachment_checklists_modal: false,
            category_set_field_list:[
                { key: 'name',},
                { key: 'description',},
                { key: 'attachment_checklist' },
                { key: 'action', sorter: false}
            ],

            attachment_checklist_key: 0,
            attachment_checklist_field_list:[
                { key: 'name',},
                { key: 'description',},
                { key: 'required' },
                { key: 'action', sorter: false}
            ]
            
        }
    },
    computed: {
        isValid () { 
            if(this.$v.category.$invalid) {
                return false;
            }
            if(this.collapse_attachment && (this.selected_attachment_type == 'default' && this.attachment_list.length <= 0)){
                return false;
            }
            if(this.collapse_attachment && (this.selected_attachment_type == 'set' && this.category_set_list.length <= 0)){
                return false;
            }
            return true;
        },
        isDirty () { return this.$v.category.$anyDirty },
        attachment_checklist_list: function () {
            return this.selected_set?.attachment_checklist;
        }
    },
    validations: {
        category: {
            category: { required, minLength: minLength(3) },
            color: { required },
        }
    },
    created () {
        this.$Progress.start()
        this.validate();
        this.$emit('activeTab', 1);
        this.$Progress.finish()
        if(!this.$store.getters['can']('document-template-category-add-attachment-checklist')) {
            this.collapse_attachment = false;
        }
    },
    methods: {
        selectionChange: function (val) {
            this.category_set_list = []
            this.attachment_list = []
        },
        addNewSet: function () {
            this.category_set_key++;
            let object = {
                name: this.new_set.name,
                description: this.new_set.description,
                attachment_checklist: this.new_set.attachment_checklist
            }
            this.category_set_list.push(object);
            this.new_set.name = null
            this.new_set.description = null
            this.new_set.attachment_checklist = []
        },
        addNewChecklist: function () {
            this.attachment_checklist_key++;
            let row = {
                name: this.new_checklist.name, 
                description: this.new_checklist.description, 
                required: this.new_checklist.required
            }
            this.selected_set.attachment_checklist.push(row)
            this.new_checklist.name = '';
            this.new_checklist.description = '';
            this.new_checklist.required = false;
        },
        showAttachmentChecklistModal: function (data, is_new_set=false) {
            this.attachment_checklists_modal = true;
            this.attachment_checklist_key++;
            this.selected_set = data
            this.adding_new_set = is_new_set;
        },  
        addAttachmentChecklist: function () {
            this.attachment_key++;
            let row = {
                name: this.attachment.name, 
                description: this.attachment.description, 
                required: this.attachment.required
            }
            this.attachment_list.push(row)
            this.attachment.name = ''
            this.attachment.description = ''
            this.attachment.required = true;
        },
        removeAttachmentChecklist: function (array, item, ind) {
            this.attachment_key++;
            this.category_set_key++
            this.attachment_checklist_key++
            array.splice(item, 1);
        },
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.category[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateCategory:function () {
            if( !this.category.category || !this.category.color){
                return false;
            } 
            return true;
        },
        clearForm: function() {
            return {
                category: null,
                color: null,
            }
        },
        createCategory: function () {
            if(!this.validateCategory()) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to add new category.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        category: this.category.category,
                        color: this.category.color,
                        attachment_set: this.selected_attachment_type == 'set' ? 1 : 0,
                        with_attachment_checklist: this.collapse_attachment,
                    }
                    if(this.collapse_attachment) {
                        if(this.selected_attachment_type == 'default') { // using default
                            data.attachment_list = this.attachment_list;
                        } else { // using set
                            data.category_set = this.category_set_list
                        }
                    }
                    return axios.post('/drs/document-template-category/store', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${this.category.category} has been added.`,
                            })
                            this.category = this.clearForm();
                            this.$v.$reset()
                            this.$v.$touch()
                            this.attachment_list = []
                            this.category_set_list= []
                            this.selected_attachment_type = 'set'
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
    },
}
</script>
